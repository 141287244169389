import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-196580555-1'); // Aqui pones tu identificador de cuenta de Google Analytics

import { history } from '../_helpers';
import { PrivateRoute } from '../_helpers';
import { alertActions } from '../_actions';

import ScrollToTop from "./scrolToTop";

// import './App.scss';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

/** Layout */
import { NavLayout } from "./views/layout/menuPrivate/navLayout";
import { MenuPublic } from "./views/layout/menuPublic/menuPublic";
import HomeFooterPublic from "./views/layout/footerPublic/homeFooterPublic";

/**Public */
import Error404 from "./views/public/error/errorPage";
import { HomeEpage } from './views/public/epage/homeEpage';
import { HomePage } from './views/public/homePage/homePage';
import { HomeLogin } from './views/public/loginPage/homeLogin';
import { HomeContacto } from './views/public/contacto/homeContacto';
import { Politicas } from './views/public/infoTerminoYmas/politicas';
import { HomeBlog } from './views/public/publicacionesYBlog/homeBlog';
import { HomeServicios } from './views/public/servicios/homeServicios';
import { HomeConocenos } from './views/public/conocenos/homeConocenos';
import { HomePreguntas } from './views/public/preguntas/homePreguntas';
import { TerminoDeUso } from './views/public/infoTerminoYmas/terminoDeUso';
import { HomePostUnico } from './views/public/publicacionesYBlog/homePostUnico';
import { HomeEspecialistas } from './views/public/especialistas/homeEspecialista';
import { ModalidadVideoChat } from './views/public/modalidades/modalidadVideoChat';
import { HomePublicaciones } from './views/public/publicacionesYBlog/homePublicaciones';
import { HomeLandingPaciente } from './views/public/landingPage/paciente/homeLandingPaciente';
import { HomePreguntasEspecialista } from './views/public/preguntas/homePreguntasEspecialista';
import { ModalidadRespuestaEscrita } from './views/public/modalidades/modalidadRespuestaEscrita';
import { HomeLandingEspecialista } from './views/public/landingPage/especialista/homeLandingEspecialista';
import { ConfigurarCuentaEspecialista } from './views/public/infoTerminoYmas/configurarCuentaEspecialista';

/**Privates */
import { HomeDashBoard } from './views/private/dashboard/homeDashboard';


class App extends Component {
    constructor(props) {
        super(props);

        // ReactGA.initialize('UA-196781829-1'); // Aqui pones tu identificador de cuenta de Google Analytics Calidad
        ReactGA.initialize('UA-196580555-1'); // Aqui pones tu identificador de cuenta de Google Analytics Produccion
        history.listen(location => ReactGA.pageview(location.pathname));
        // history.listen((location, action) => {
        //     // clear alert on location change
        //   //this.handlleNofificacion()
        // });


        //this.handleCloseNotify = this.handleCloseNotify.bind(this)

        // this.props.dispatch(alertActions.success('Funcionando'));
    }

    handleCloseNotify = (event, reason) => {
        const { dispatch } = this.props;
        if (reason === 'clickaway') {
            return;
        }
        dispatch(alertActions.clear());
    };

    render() {
        const { alert, loggedIn } = this.props;
        return(
            <div className="App">

                <Router history={history} basename={'/'}>
                    <ScrollToTop/>
                    {loggedIn 
                        ? <NavLayout />
                        : <MenuPublic/>
                    }
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/login" component={HomeLogin} />
                        <Route exact path="/blog" component={HomeBlog} />
                        <Route exact path="/politicas" component={Politicas} />
                        <Route exact path="/servicios" component={HomeServicios} />
                        <Route exact path="/conocenos" component={HomeConocenos} />
                        <Route exact path="/contactanos" component={HomeContacto} />
                        <Route exact path="/condiciones" component={TerminoDeUso} />
                        <Route exact path="/epage/:nickname/" component={HomeEpage} />
                        <Route exact path="/publicaciones" component={HomePublicaciones} />
                        <Route exact path="/videochatenvivo" component={ModalidadVideoChat} />
                        <Route exact path="/preguntas-frecuentes" component={HomePreguntas} />
                        <Route exact path="/registro-paciente" component={HomeLandingPaciente} />
                        <Route exact path="/post/:nickname/:keydPost" component={HomePostUnico} />
                        <Route exact path="/nuestros-especialistas" component={HomeEspecialistas} />
                        <Route exact path="/respuestaescrita" component={ModalidadRespuestaEscrita} />
                        <Route exact path="/registro-especialista" component={HomeLandingEspecialista} />
                        <Route exact path="/preguntasfrecuentesespecialistas" component={HomePreguntasEspecialista} />
                        <Route exact path="/comoconfigurarcuentaespecialista" component={ConfigurarCuentaEspecialista} />

                        <PrivateRoute exact path="/paciente" component={HomeDashBoard} />

                        <Route component={Error404} />
                    </Switch>

                    {loggedIn
                        ? 'footerPrivate'
                        : <HomeFooterPublic />
                    }
                </Router>
                <div>
                {alert.message &&
                    <Snackbar
                        className={alert.classe}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        open={alert.open}
                        autoHideDuration={6000}
                        onClose={this.handleCloseNotify}
                        ContentProps={{
                        'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{alert.message}</span>}
                        action={[
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleCloseNotify} >
                            <CloseIcon />
                        </IconButton>,

                        ]}
                    />
                }
              </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { loggedIn, admin } = state.authReducer;
    return {
        alert,
        loggedIn, admin
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 